<template>
  <div class="base-text-area">
    <b-form-group :label="label" label-class="form-label" :label-for="id">
      <b-form-textarea
        :id="id"
        v-model="formValue"
        @input="$emit('change', formValue)"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "BaseTextArea",
  components: {},
  props: {
    label: { type: String, default: "Datum" },
    value: { type: String, default: "" },
    id: { type: String, default: "text-area" },
  },
  data() {
    return {
      formValue: this.value,
    };
  },

  watch: {
    value: function(newVal) {
      this.formValue = newVal;
    },
  },
};
</script>

<style scoped></style>
