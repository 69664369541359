<template>
  <div class="account">
    <h3>{{ $t("account.title") }}</h3>
    <div class="version">Version {{ appVersion }}</div>
    <b-card bg-variant="light">
      <b-card-header header-tag="header" role="tab">
        <div block v-b-toggle.keyForm variant="info">
          {{ $t("account.addApiKey") }}
        </div>
      </b-card-header>
      <b-collapse id="keyForm" visible accordion="keyForm" role="tabpanel">
        <b-card-body>
          <div class="form-container">
            <SourcesForm />
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card bg-variant="light">
      <b-card-header header-tag="header" role="tab">
        <div block v-b-toggle.userForm variant="info">
          {{ $t("account.changeProfile") }}
        </div>
      </b-card-header>
      <b-collapse id="userForm" visible accordion="userForm" role="tabpanel">
        <b-card-body>
          <UserForm :userData="userData" />
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import AZ from "@/utils/AzApi";
import SourcesForm from "@/components/SourcesForm";
import UserForm from "@/components/UserForm";

export default {
  name: "Account",
  components: {
    UserForm,
    SourcesForm,
  },
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      user: AZ.user(),
      actionResult: {
        error: false,
        message: "",
      },
      userData: {
        username: AZ.user().username,
        id: AZ.user().id,
      },
    };
  },
};
</script>

<style scoped>
button {
  margin: 1.4rem 0;
}

.card-body {
  padding: 0;
}

.form-container {
  padding: 1.4rem;
}

.card-header {
  font-size: 1.2rem;
  background-color: var(--primary);
  color: white;
}

.card {
  margin: 2rem 0;
}

.version {
  color: rgb(160, 160, 160);
}
</style>
