<template>
  <div class="sources-form">
    <div class="form-container">
      <b-form-group label="Quelle">
        <v-select
          :options="sourceOptions"
          v-model="formData.name"
          @input="setDefaultConfig"
        ></v-select>
      </b-form-group>
      <b-form-group label="Beschreibung">
        <b-form-input v-model="formData.description"></b-form-input>
      </b-form-group>

      <b-form-group label="Api Key">
        <b-form-textarea v-model="formData.config"></b-form-textarea>
      </b-form-group>
      <b-button @click="persistSource(formData)">Key hinzufügen</b-button>
      <div
        v-for="source in sources"
        v-bind:key="source.id"
        class="key-container"
      >
        {{ source.name }}
        <span v-if="source.description !== ''">
          ({{ source.description }})</span
        >
        <b-card class="key-card">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="key-info">{{ source.config }}</div>
            <img
              @click="deleteSource(source.id)"
              src="delete-icon-dark.svg"
              alt=""
              class="ml-3"
            />
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import AZ from "@/utils/AzApi";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "SourcesForm",
  components: {},
  props: {
    selectedUserId: { type: String, default: undefined },
  },
  data() {
    return {
      actionResult: {
        error: false,
        message: "",
      },
      linearFormData: {},
      zohoFormData: {},
      formData: {},
      sources: [],
      availableSources: [],
      sourceOptions: ["linear", "zoho", "plain"],
      userId: this.selectedUserId,
    };
  },

  computed: {
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? this.$t("toast.saveError", { item: this.$t("sources.item") })
        : this.$t("toast.saveSuccess", { item: this.$t("sources.item") });
    },
    availableConfigs() {
      return this.availableSources.map((source) => source.config);
    },
    currentDateFormatted() {
      return new Date().toLocaleString(undefined, {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
    },
  },

  mounted() {
    if (!this.userId) this.userId = AZ.user().id;
    this.retrieveSources();
    this.retrieveAvailableSources();
  },

  methods: {
    setDefaultFormData() {
      this.formData = {
        name: this.availableSources[0].name,
        description: "",
        userId: this.userId,
        id: uuidv4(),
        config: this.availableSources[0].config,
      };
    },

    setDefaultConfig() {
      this.formData.config = this.availableSources.find(
        (source) => source.name === this.formData.name
      ).config;
    },
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },

    retrieveSources() {
      AZ.getSources(this.userId).then((response) => {
        this.sources = response.data;
      });
    },
    retrieveAvailableSources() {
      AZ.getAvailableSources().then((response) => {
        this.availableSources = response.data;
        this.sourceOptions = response.data.map((source) => source.name);
        this.setDefaultFormData();
      });
    },

    deleteSource(id) {
      AZ.deleteSource(id)
        .then(() => {
          this.retrieveSources();
          this.actionResult = {
            error: false,
            message: this.$t("toast.deletedAt", {
              item: this.$t("sources.item"),
              date: this.currentDateFormatted,
            }),
          };
          this.makeToast();
        })
        .catch((err) => {
          this.actionResult = {
            error: true,
            message: this.$t("toast.errorOccurred", { error: err.message }),
          };
          this.makeToast();
        });
    },

    persistSource(formData) {
      if (this.availableConfigs.includes(formData.config)) {
        this.actionResult = {
          error: true,
          message: this.$t("toast.errorOccurred", {
            error: this.$t("sources.changeInitialValue"),
          }),
        };
        this.makeToast();
        return;
      }
      AZ.persistSource(formData)
        .then(() => {
          this.retrieveSources();
          this.actionResult = {
            error: false,
            message: this.$t("toast.savedAt", {
              item: this.$t("sources.item"),
              date: this.currentDateFormatted,
            }),
          };
          this.makeToast();
        })
        .catch((err) => {
          this.actionResult = {
            error: true,
            message: this.$t("toast.errorOccurred", { error: err.message }),
          };
          this.makeToast();
        });
    },
  },
};
</script>

<style scoped>
button {
  margin: 1.4rem 0;
}

.card-body {
  padding: 0;
}

.form-container {
  padding: 1.4rem;
}

.card-header {
  font-size: 1.2rem;
  background-color: var(--primary);
  color: white;
}

.card {
  margin: 2rem 0;
}

.key-container {
  margin: 1rem 0;
}
.key-card {
  margin: 0;
  padding: 1rem;
}

.key-info {
  max-width: 80%;
  max-height: 320px;
  overflow-y: scroll;
}
</style>
