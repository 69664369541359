<template>
  <div class="base-checkbox-switch">
    <b-form-group :label="label" :label-for="id">
      <b-form-checkbox
        class="my-3"
        name="check-button"
        switch
        v-model="formValue"
        :id="id"
        @input="$emit('change', formValue)"
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "BaseCheckboxSwitch",
  components: {},
  props: {
    label: { type: String, default: "Datum" },
    value: { type: Boolean, default: false },
    id: { type: String, default: "" },
  },
  data() {
    return {
      formValue: this.value,
    };
  },

  watch: {
    value: function(newVal) {
      this.formValue = newVal;
    },
  },
};
</script>

<style scoped></style>
