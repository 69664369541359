<template>
  <div class="mb-3">
    <label :for="label" :class="id === 'startTime' ? 'mb-1' : 'form-label'">{{
      label
    }}</label>
    <img
      v-if="id === 'startTime'"
      src="refresh-icon-dark.svg"
      alt="refresh"
      class="ml-2 mb-1"
      @click="$emit('refresh')"
    />

    <b-input-group>
      <b-form-input
        :id="label"
        v-model="time"
        type="text"
        placeholder="HH:mm"
        :state="timeState"
        @change="$emit('change', time)"
        @blur="autocompleteTime"
      ></b-form-input>
      <b-input-group-append>
        <b-form-timepicker
          v-model="time"
          button-only
          right
          :aria-controls="label"
          locale="de"
          v-bind="labels.de || {}"
          hide-header
          noCloseButton
          minutes-step="15"
          @input="$emit('change', time)"
        ></b-form-timepicker>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: "BaseTimePicker",
  components: {},
  props: {
    label: { type: String, default: "Zeit" },
    value: { type: String, default: "" },
    id: { type: String, default: "" },
  },
  data() {
    return {
      labels: {
        de: {
          labelHours: "Stunden",
          labelMinutes: "Minuten",
          labelSeconds: "Sekunden",
          labelIncrement: "Erhöhen",
          labelDecrement: "Verringern",
          labelSelected: "Ausgewählte Zeit",
          labelNoTimeSelected: "Keine Zeit ausgewählt",
        },
      },
      time: this.value,
    };
  },

  computed: {
    timeState() {
      return (
        !this.time ||
        this.time.match(
          /^(?:([0-1]?\d|2[0-3])?(?::([0-5]?\d))?(?::([0-5]?\d))?(?:([0-2][0-9][0-5][0-9]))?)$/
        ) !== null ||
        this.time === ""
      );
    },
  },

  watch: {
    value: function(newVal) {
      this.time = newVal;
    },
  },
  methods: {
    autocompleteTime() {
      if (!this.time || this.time?.match(/^[0-9]+$/) === null) return;
      const timeArray = this.time.split("");
      switch (this.time.length) {
        case 1:
          this.time = "0" + this.time + ":00";
          break;
        case 2:
          if (this.time.match(/^[0-2][0-9]$/) !== null) this.time += ":00";
          break;
        case 3:
          this.time = "0" + timeArray[0] + ":" + timeArray[1] + timeArray[2];
          break;
        case 4:
          if (this.time.match(/^[0-2][0-9][0-5][0-9]$/) !== null)
            this.time =
              timeArray[0] + timeArray[1] + ":" + timeArray[2] + timeArray[3];
          break;
      }
      this.$emit("change", this.time);
    },
  },
};
</script>
