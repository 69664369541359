export default {
  methods: {
    convertMinutesInHHmmFormat(minutes) {
      const minVal = minutes % 60;
      const hourVal = Math.floor(minutes / 60);
      const mins = minVal < 10 ? "0" + minVal : minVal;
      const hours = hourVal < 10 ? "0" + hourVal : hourVal;
      return hours + ":" + mins;
    },
    calculateMinutes(time) {
      const timeStr = time || new Date().toTimeString().slice(0, 5);
      const timeArr = timeStr.split(":");
      return parseFloat(timeArr[0]) * 60 + parseFloat(timeArr[1]);
    },
  },
};
