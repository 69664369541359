import axios from "axios";
import router from "@/router/index";

const baseUrl = process.env.VUE_APP_BACKENDURL;

axios.interceptors.request.use(
  (config) => {
    const token = AZ.user().access_token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      originalRequest.url === `${baseUrl}/refreshAuthorization/`
    ) {
      if (router.currentRoute.name !== "login") AZ.logout();
      return Promise.reject(error);
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return AZ.refresh(AZ.user().refresh_token).then(() => {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + AZ.user().access_token;
        return axios(originalRequest);
      });
    }
    throw error.response.data;
  }
);

const AZ = {
  user: () =>
    JSON.parse(
      sessionStorage.getItem("AZUSER") ||
        JSON.stringify({
          expires_in: 0,
          access_token: "",
          refresh_token: "",
          name: "",
          isLoggedIn: false,
        })
    ),
  login: (username, password) => {
    const params = new URLSearchParams();
    params.append("grant_type", "password");
    params.append("username", username);
    params.append("password", password);
    const url = `${baseUrl}/authorize/`;
    return axios
      .post(url, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((user) => {
        AZ.getProfile(user.data.access_token).then((response) => {
          user.data.isLoggedIn = true;
          user.data.username = response.data.username;
          user.data.id = response.data.id;
          user.data.role = response.data.role;

          sessionStorage.setItem("AZUSER", JSON.stringify(user.data));
          window.dispatchEvent(new Event("AZUSERCHANGE"));
          router.push("/");
        });

        return user;
      });
  },
  refresh: (refresh_token) => {
    const params = new URLSearchParams();
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", refresh_token);
    const url = `${baseUrl}/refreshAuthorization/`;
    return axios
      .post(url, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((user) => {
        user.data.isLoggedIn = true;
        user.data.username = AZ.user().username;
        user.data.id = AZ.user().id;
        user.data.role = AZ.user().role;

        sessionStorage.setItem("AZUSER", JSON.stringify(user.data));
        window.dispatchEvent(new Event("AZUSERCHANGE"));
        return user;
      });
  },
  logout: () => {
    sessionStorage.removeItem("AZUSER");
    window.dispatchEvent(new Event("AZUSERCHANGE"));
    localStorage.setItem("AZ_CREDENTIALS_FLUSH", "logout");
    localStorage.removeItem("AZ_CREDENTIALS_FLUSH", "logout");
    router.push("/login");
    return sessionStorage;
  },

  getProfile: (token) => {
    const bearerToken = token ? token : AZ.user().access_token;
    const url = `${baseUrl}/profile`;
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    });
  },

  // Activity Tracking Management for User

  getSuggestions: () => {
    const url = `${baseUrl}/suggestions/`;
    return axios.get(url);
  },
  getActivities: () => {
    const url = `${baseUrl}/activities/`;
    return axios.get(url);
  },

  getActivitiesForUser: (
    user,
    startDate = "",
    endDate = "",
    showAdditionalFields = false
  ) => {
    let params = "";
    if (startDate !== "") {
      params = `?datefrom=${startDate}T00:00:00`;
    }

    if (startDate == "" && endDate !== "") {
      params = `?dateuntil=${endDate}T23:59:59`;
    } else if (endDate !== "") {
      params = `${params}&dateuntil=${endDate}T23:59:59`;
    }
    if (showAdditionalFields) {
      if (params === "") params = params + "?additionalfields=true";
      else params = params + "&additionalfields=true";
    }

    const url = `${baseUrl}/activities/${user.id}${params}`;
    return axios
      .get(url, {
        headers: {
          Accept: `text/csv`,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          AZ.constructFileName(user.username, startDate, endDate)
        );
        document.body.appendChild(link);
        link.click();
      });
  },

  constructFileName(username, startDate, endDate) {
    if (startDate !== "" && endDate !== "") {
      return `${username}-${startDate}-${endDate}.csv`;
    } else if (startDate !== "") {
      return `${username}-${startDate}.csv`;
    } else if (endDate !== "") {
      return `${username}-${endDate}.csv`;
    } else {
      return `${username}.csv`;
    }
  },

  createOrUpdateActivity: (payload) => {
    const url = `${baseUrl}/activities/`;
    return axios.put(url, payload);
  },
  deleteActivity: (id) => {
    const url = `${baseUrl}/activities/${id}`;
    return axios.delete(url);
  },

  // API Key Management for User

  createApiKey: (payload) => {
    const url = `${baseUrl}/apikey/`;
    return axios.post(url, payload);
  },
  updateApiKey: (payload) => {
    const url = `${baseUrl}/apikey/${payload.id}`;
    return axios.put(url, payload);
  },
  deleteApiKey: (id) => {
    const url = `${baseUrl}/apikey/${id}`;
    return axios.delete(url);
  },

  // User Management for Admin

  retrieveAllUsers: () => {
    const url = `${baseUrl}/users/`;
    return axios.get(url);
  },
  retrieveUser: (userId) => {
    const url = `${baseUrl}/users/${userId}`;
    return axios.get(url);
  },

  persistUser: (user) => {
    const url = `${baseUrl}/users/`;
    return axios.put(url, user);
  },
  deleteUser: (userId) => {
    const url = `${baseUrl}/users/${userId}`;
    return axios.delete(url);
  },
  getSources: (userId) => {
    const id = userId ? userId : AZ.user().id;
    const url = `${baseUrl}/suggestions/sources/${id}`;
    return axios.get(url);
  },
  getAvailableSources: () => {
    const url = `${baseUrl}/suggestions/availablesources/`;
    return axios.get(url);
  },
  persistSource: (source) => {
    const url = `${baseUrl}/suggestions/sources/`;
    return axios.put(url, source);
  },
  deleteSource: (sourceId) => {
    const url = `${baseUrl}/suggestions/sources/${sourceId}`;
    return axios.delete(url);
  },
};

export default AZ;
