<template>
  <div class="home">
    <TimeTrackerFormNew
      :input="formData"
      @reset="resetFormData"
      @refresh="resetFormTime"
      @update="getActivities"
      :itemsRunning="itemsRunning"
    />

    <div class="table-container">
      <h3>{{ $t("activities.list") }}</h3>
      <ActivitiesTable
        :entries="items"
        @edit="setFormData($event)"
        @restart="restartItem"
        @stop="stopItem"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TimeTrackerFormNew from "@/components/TimeTrackerFormNew.vue";
import ActivitiesTable from "@/components/ActivitiesTable.vue";
import { v4 as uuidv4 } from "uuid";
import AZ from "@/utils/AzApi";
import config from "@/utils/config";
import TimeConverter from "../mixins/TimeConverter";

export default {
  name: "Home",
  components: {
    TimeTrackerFormNew,
    ActivitiesTable,
  },
  mixins: [TimeConverter],

  data() {
    return {
      items: [],
      formData: {},
      activityToDelete: 0,
      formConfig: JSON.parse(JSON.stringify(config))?.form?.activity,
    };
  },
  computed: {
    itemsRunning() {
      return this.items.filter((item) => item.running);
    },
  },
  mounted() {
    this.getActivities();
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("confirm-modal");
    },

    getActivities() {
      AZ.getActivities().then((response) => {
        this.items = response.data;
        this.items.forEach((item) => {
          item.date = item.startTime.slice(0, 10);
          item.start = item.startTime.slice(11, 16);
          item.end = item.endTime ? item.endTime.slice(11, 16) : null;
          item.duration = this.convertMinutesInHHmmFormat(
            item.durationInMinutes
          );
          item._showDetails = true;
        });
        this.resetFormData();
      });
    },
    deleteActivity() {
      AZ.deleteActivity(this.activityToDelete)
        .then(() => {
          const indexToDelete = this.items.findIndex(
            (item) => item.id === this.activityToDelete
          );
          this.items.splice(indexToDelete, 1);
          this.closeModal();
        })
        .catch((error) => {
          this.actionResult = {
            error: true,
            message: this.$t("toast.errorOccurred", { error: error.message }),
          };
          this.makeToast();
        });
    },
    resetFormTime() {
      const now = new Date();
      this.formData.startTime = now.toTimeString().slice(0, 5);
      this.formData.date = now.toISOString().slice(0, 10);
      this.formData.endTime = "";
      this.formData.duration = "";
    },

    restartItem(item) {
      const now = new Date();
      const activity = {
        id: uuidv4(),
        userId: JSON.parse(window.sessionStorage.AZUSER).id,
        title: item.title,
        description: item.description,
        startTime:
          now.toISOString().slice(0, 11) + now.toTimeString().slice(0, 5),
        endTime: null,
        source: item.source || "",
        externalId: item.externalId || 0,
        additionalFields: item.additionalFields || {}
      };

      AZ.createOrUpdateActivity(activity).then((response) => {
        this.getActivities();
      });
    },

    stopItem(item) {
      const now = new Date();
      const date = item.startTime.slice(0, 11);
      const activity = {
        id: item.id,
        userId: JSON.parse(window.sessionStorage.AZUSER).id,
        title: item.title,
        description: item.description,
        startTime: item.startTime,
        endTime: date + now.toTimeString().slice(0, 5),
        source: item.source || "",
        externalId: item.externalId || 0,
        additionalFields: item.additionalFields || {}
      };

      AZ.createOrUpdateActivity(activity).then((response) => {
        this.getActivities();
      });
    },

    resetFormData() {
      const showStartTime = this.formConfig.find((container) => {
        return container.fields.find((field) => field.id === "startTime");
      });
      const now = new Date();
      this.formData = {
        id: "",
        issue: {
          title: "",
          source: "",
          externalId: "",
        },
        description: "",
        startTime: showStartTime ? now.toTimeString().slice(0, 5) : "00:00",
        date: now.toISOString().slice(0, 10),
        endTime: "",
        duration: "",
        additionalFields: {}
      };
    },

    setEndTimeOffsetInMinutes(item) {
      const startTime = item.startTime;
      const start = Date.parse(startTime);
      const endTime = new Date(start + item.durationInMinutes * 60000);
      return endTime.toTimeString().slice(0, 5);
    },

    setFormData(item) {
      this.formData = {
        id: item.id,
        issue: {
          title: item.title,
          source: item.source,
          externalId: item.externalId,
        },
        description: item.description,
        startTime: item.start,
        endTime: item.end ? item.end : this.setEndTimeOffsetInMinutes(item),
        date: item.date,
        duration: item.durationInMinutes,
        additionalFields: item.additionalFields || {}
      };
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 1.2rem;
  margin: 1rem 0;
  font-weight: bold;
}

.table-container {
  padding: 1.2rem;
}

@media (max-width: 768px) {
  .table-container {
    padding: 0;
  }
  h3 {
    text-align: center;
    margin: 2rem 0;
  }
}
</style>
