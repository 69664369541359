<template>
  <div class="account">
    <div class="form-container">
      <b-form-group :label="$t('account.email')">
        <b-form-input
          type="text"
          v-model="userFormData.username"
        ></b-form-input>
      </b-form-group>
      <b-form-group v-if="user.role === 'admin'" :label="$t('account.role')">
        <b-form-select
          :options="roleOptions"
          v-model="userFormData.role"
        ></b-form-select>
      </b-form-group>
      <b-form-group :label="$t('account.newPassword')">
        <b-form-input
          v-model="userFormData.passwordFirst"
          type="password"
        ></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('account.confirmPassword')">
        <b-form-input
          v-model="userFormData.passwordSecond"
          type="password"
        ></b-form-input>
      </b-form-group>
      <b-button class="float-right" @click="updateUser">{{
        $t("account.save")
      }}</b-button>
    </div>
  </div>
</template>

<script>
import AZ from "@/utils/AzApi";

export default {
  name: "UserForm",
  components: {},
  props: {
    userData: { type: Object },
  },
  data() {
    return {
      user: AZ.user(),
      actionResult: {
        error: false,
        message: "",
      },
      userFormData: {},
      roleOptions: [
        { value: "admin", text: "admin" },
        { value: "user", text: "user" },
        { value: "extern", text: "extern" },
      ],
    };
  },

  computed: {
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? this.$t("toast.saveError", { item: this.$t("account.item") })
        : this.$t("toast.saveSuccess", { item: this.$t("account.item") });
    },
  },

  mounted() {
    if (this.userData) {
      this.userFormData = { ...this.userData };
    } else if (this.user) {
        this.userFormData.username = this.user.username;
        this.userFormData.id = this.user.id;
      }
  },

  methods: {
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },

    validateFormInput() {
      if (
        this.userFormData.passwordFirst !== this.userFormData.passwordSecond
      ) {
        this.actionResult = {
          error: true,
          message: this.$t("toast.errorOccurred", {
            error: this.$t("toast.passwordsMismatch"),
          }),
        };
        return false;
      }

      if (
        !this.userFormData.username ||
        this.userFormData.username.length <= 3
      ) {
        this.actionResult = {
          error: true,
          message: this.$t("toast.errorOccurred", {
            error: this.$t("toast.usernameError"),
          }),
        };
        return false;
      }

      if (
        !this.userData.username &&
        (!this.userFormData.passwordFirst ||
          this.userFormData.passwordFirst.length <= 5)
      ) {
        this.actionResult = {
          error: true,
          message: this.$t("toast.errorOccurred", {
            error: this.$t("toast.passwordError"),
          }),
        };
        return false;
      }
      return true;
    },

    updateUser() {
      if (!this.validateFormInput()) {
        this.makeToast();
        return;
      }
      const user = {
        id: this.userFormData.id,
        username: this.userFormData.username,
        password: this.userFormData.passwordFirst,
      };
      if (this.userFormData.role) user.role = this.userFormData.role;

      AZ.persistUser(user)
        .then(() => {
          this.actionResult = {
            error: false,
            message: this.$t("toast.deletedAt", {
              item: this.$t("account.item"),
              date: new Date().toLocaleString(undefined, {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              }),
            }),
          };
          this.makeToast();
          this.$emit("update");
        })
        .catch((err) => {
          this.actionResult = {
            error: true,
            message: this.$t("toast.errorOccurred", {
              error: err,
            }),
          };
          this.makeToast();
        });
    },
  },
};
</script>

<style scoped>
button {
  margin: 1.4rem 0;
}

.card-body {
  padding: 0;
}

.form-container {
  padding: 1.4rem;
}

.card-header {
  font-size: 1.2rem;
  background-color: var(--primary);
  color: white;
}

.card {
  margin: 2rem 0;
}
</style>
