<template>
  <b-modal
    id="export-modal"
    :title="$t('admin.choosePeriodForExport')"
    hide-footer
    centered
  >
    <div class="info my-2">User: {{ selectedUser.username }}</div>
    <BaseDatePicker
      :label="$t('admin.exportStartDate')"
      @change="formData.startDate = $event"
      :value="formData.startDate"
    />
    <BaseDatePicker
      :label="$t('admin.exportEndDate')"
      @change="formData.endDate = $event"
      :value="formData.endDate"
    />
    <b-form-checkbox
      v-model="formData.exportAdditionalFields"
      switch
      class="my-3"
    >
      {{ $t("admin.exportAdditionalFields") }}</b-form-checkbox
    >
    <b-button @click="$emit('export', formData)">{{
      $t("admin.exportData")
    }}</b-button>
  </b-modal>
</template>

<script>
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "ExportModal",
  components: {
    BaseDatePicker,
  },
  props: {
    selectedUser: { type: Object },
  },

  data() {
    return {
      formData: {
        selectedUser: this.selectedUser,
        startDate: "",
        endDate: "",
        exportAdditionalFields: true,
      },
    };
  },
  mounted() {
    this.formData.selectedUser = this.selectedUser;
  },
  watch: {
    selectedUser: function(newVal) {
      this.formData.selectedUser = newVal;
    },
  },
};
</script>

<style scoped>
.info {
  color: var(--primary);
}
</style>
