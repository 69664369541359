<template>
  <div class="base-select-input">
    <b-form-group :label="label" label-class="form-label" :label-for="id">
      <v-select
        @search:focus="setOptions"
        selectOnTab
        :id="id"
        v-model="formValue"
        :options="options"
        label="title"
        @input="$emit('change', formValue)"
      ></v-select>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  components: {},
  props: {
    label: { type: String, default: "Datum" },
    value: { type: [Object, String], default: "" },
    id: { type: String, default: "" },
    getOptions: { type: Function },
  },
  data() {
    return {
      formValue: this.value,
      options: [],
    };
  },

  watch: {
    value: function(newVal) {
      this.formValue = newVal;
    },
  },

  methods: {
    async setOptions() {
      this.options = await this.getOptions();
    },
  },
};
</script>

<style scoped></style>
