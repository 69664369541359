<!-- @format -->

<template>
  <div class="time-tracker-form">
      <b-card bg-variant="light">
          <b-card-header header-tag="header" role="tab" :header-bg-variant="cardHeaderBgColor">
              <div class="d-flex justify-content-between">
                  <div block v-b-toggle.form variant="info">{{ cardTitle }}</div>
                  <div v-if="showStartTime" class="d-flex gap-2">
                      <img
                          v-if="itemsRunning.length === 0"
                          src="start-icon-light.svg"
                          @click="
                              $emit('refresh');
                              persistActivity();
                          "
                      />
                      <img v-else src="stop-icon-light.svg" @click="stopTimer(itemsRunning[0])" />
                  </div>
              </div>
          </b-card-header>
          <b-collapse id="form" visible accordion="form" role="tabpanel">
              <b-card-body>
                  <div class="form-container">
                      <b-alert :show="validation.error">{{ validation.message }}</b-alert>
                      <div class="layout-container">
                          <div v-for="container in formConfig" :key="container.container" class="input-wrapper">
                              <div v-for="field in container.fields" :key="field.id">
                                  <component
                                      :is="field.type"
                                      :label="$t(field.label)"
                                      :id="field.id"
                                      :value="retrieveValueForKey(field)"
                                      @change="handleChange($event, field)"
                                      :getOptions="getOptions"
                                      :key="formData[field.type]"
                                      @refresh="$emit('refresh')"
                                  ></component>
                              </div>
                          </div>
                      </div>

                      <b-button class="" variant="primary" @click="persistActivity">{{
                          buttonText
                      }}</b-button>
                      <b-button
                          class=""
                          variant="outline-secondary"
                          @click="
                              $emit('reset');
                              validation.error = false;
                              validation.message = '';
                          "
                          >{{ $t("activities.reset") }}</b-button
                      >
                  </div>
              </b-card-body>
          </b-collapse>
      </b-card>
  </div>
</template>

<script>
import BaseTimePicker from "./BaseTimePicker.vue";
import BaseDatePicker from "./BaseDatePicker.vue";
import { v4 as uuidv4 } from "uuid";
import AZ from "@/utils/AzApi";
import BaseSelect from "./BaseSelect.vue";
import BaseTextArea from "./BaseTextArea.vue";
import BaseInputField from "./BaseInputField.vue";
import BaseDurationPicker from "./BaseDurationPicker.vue";
import BaseCheckboxSwitch from "./BaseCheckboxSwitch.vue";
import config from "@/utils/config";
import TimeConverter from "../mixins/TimeConverter";
import BaseDurationInMinutes from "./BaseDurationInMinutes.vue";

export default {
  name: "TimeTrackerFormNew",
  components: {
      BaseTimePicker,
      BaseDatePicker,
      BaseSelect,
      BaseTextArea,
      BaseInputField,
      BaseDurationPicker,
      BaseCheckboxSwitch,
      BaseDurationInMinutes
  },
  mixins: [TimeConverter],
  props: {
      itemsRunning: {
          type: Array,
          default: () => {
              return [];
          }
      },
      input: {
          type: Object,
          default: () => {
              return {
                  id: "",
                  issue: {
                      title: "",
                      source: "",
                      externalId: ""
                  },
                  description: "",
                  startTime: "",
                  endTime: "",
                  date: ""
              };
          }
      }
  },
  data() {
      return {
          formConfig: JSON.parse(JSON.stringify(config))?.form?.activity,
          formData: { id: "", issue: { title: "" } },
          mode: "stopwatch",
          suggestionOptions: [],
          actionResult: {
              error: false,
              message: ""
          },
          validation: {
              error: false,
              message: ""
          }
      };
  },
  computed: {
      cardTitle() {
          return this.formData.id !== ""
              ? this.$t("activities.update", { date: this.formData.date })
              : this.$t("activities.book");
      },
      showStartTime() {
          return this.formConfig.find(container => {
              return container.fields.find(field => field.id === "startTime");
          });
      },
      buttonText() {
          const isRunning = !this.formData.endTime || this.formData.endTime === "";
          if (this.formData.id === "") {
              return isRunning && this.showStartTime
                  ? this.$t("activities.startTimer")
                  : this.$t("activities.submit");
          } else {
              return isRunning && this.showStartTime ? this.$t("activities.stop") : this.$t("activities.save");
          }
      },
      cardHeaderBgColor() {
          return this.formData.id !== "" ? "secondary" : "primary";
      },
      alertType() {
          return this.actionResult.error ? "danger" : "primary";
      },
      toastHeader() {
          return this.actionResult.error
              ? this.$t("toast.saveError", { item: this.$t("activities.item") })
              : this.$t("toast.saveSuccess", { item: this.$t("activities.item") });
      },
      containerIncludesDuration() {
          return this.formConfig.find(container => {
              return container.fields.find(field => field.id === "duration");
          });
      }
  },
  mounted() {
      this.formData = JSON.parse(JSON.stringify(this.input));
  },
  watch: {
      input: function (newVal) {
          this.formData = newVal;
      }
  },
  methods: {
      retrieveValueForKey(field) {
          if (field.path.includes(".")) {
              const pathArray = field.path.split(".");
              if (!this.formData[pathArray[0]]) this.formData[pathArray[0]] = {};

              if (Object.keys(field).includes("default") && !this.formData[pathArray[0]][pathArray[1]])
                  this.formData[pathArray[0]][pathArray[1]] = field.default;
              return this.formData[pathArray[0]][pathArray[1]];
          } else {
              if (Object.keys(field).includes("default") && !this.formData[field.path]) {
                  this.formData[field.path] = field.default;
              }
              return this.formData[field.path];
          }
      },
      setValueForKey(path, value) {
          if (path.includes(".")) {
              const pathArray = path.split(".");
              if (!this.formData[pathArray[0]]) this.formData[pathArray[0]] = {};

              this.formData[pathArray[0]][pathArray[1]] = value;
          } else {
              this.formData[path] = value;
          }
      },
      handleChange(event, field) {
          if (field.id === "issue") {
              if (event == null) {
                  this.validation.error = true;
                  this.validation.message = this.$t("toast.projectempty");
                  return;
              }
          }

          if (field.checkboxGroup && event) {
            this.formConfig.forEach(container => {
                container.fields.forEach(f => {
                    if (f.checkboxGroup && field.checkboxGroup == f.checkboxGroup && f.id != field.id) {
                        this.setValueForKey(f.path, false);
                    }
                })
            });
          }
          switch (field.type) {
              case "BaseDurationInMinutes":
                  this.setEndTimeOffsetInMinutes(parseInt(event, 10));
                  this.setValueForKey(field.path, event);

                  break;
              case "BaseDurationPicker":
                  this.setEndTimeOffsetInMinutes(event);
                  this.setValueForKey(field.path, this.calculateMinutes(event));

                  break;
              case "BaseTimePicker":
                  const timeType = field.id.includes("start") ? "start" : "end";
                  this.setDuration(timeType, event);
                  break;
              default:
                  this.setValueForKey(field.path, event);
                  break;
          }

          this.checkDuration(parseInt(this.formData.duration, 10) || 0);
      },
      makeToast() {
          this.$bvToast.toast(this.actionResult.message, {
              title: this.toastHeader,
              variant: this.alertType,
              solid: true
          });
      },
      stopTimer(item) {
          const now = new Date();
          this.formData = {
              id: item.id,
              issue: {
                  title: item.title,
                  source: item.source,
                  externalId: item.externalId
              },
              description: item.description,
              startTime: item.start,
              endTime: now.toTimeString().slice(0, 5),
              endDate: now.toISOString().slice(0, 10),
              date: item.date
          };
          this.persistActivity();
      },

      async getOptions() {
          const response = await AZ.getSuggestions();
          return await response?.data;
      },
      resetStartTime() {
          const now = new Date();
          this.formData.startTime = now.toTimeString().slice(0, 5);
          this.formData.date = now.toISOString().slice(0, 10);
          this.$emit("refresh");
      },

      checkDuration(time) {
          const minutes = isNaN(time) ? this.calculateMinutes(time) : time;
          const containerIncludingDuration = this.formConfig.find(container => {
              return container?.fields.find(field => field.id === "duration");
          });
          const durationField = containerIncludingDuration?.fields.find(field => field.id === "duration");

          //Check duration only if a project is selected
          if (this.formData.issue != null && this.formData.issue.title == "") {
              return;
          }

          if (durationField?.minimum && minutes < durationField?.minimum) {
              this.validation = {
                  error: true,
                  message: this.$t("toast.durationTooLow", {
                      min: this.convertMinutesInHHmmFormat(durationField?.minimum)
                  })
              };
              return false;
          } else if (durationField?.maximum && minutes > durationField?.maximum) {
              this.validation = {
                  error: true,
                  message: this.$t("toast.durationTooHigh", {
                      max: this.convertMinutesInHHmmFormat(durationField?.maximum)
                  })
              };
              return false;
          } else {
              this.validation = {
                  error: false,
                  message: ""
              };
              return true;
          }
      },
      dateToISOLikeButLocal(date) {
          const offsetMs = date.getTimezoneOffset() * 60 * 1000;
          const msLocal = date.getTime() - offsetMs;
          const dateLocal = new Date(msLocal);
          const iso = dateLocal.toISOString();
          const isoLocal = iso.slice(0, 19);
          return isoLocal;
      },

      setEndTimeOffsetInMinutes(time) {
          const minutes = isNaN(time) ? this.calculateMinutes(time) : time;
          if (!this.checkDuration(time) && minutes >= 1440) return;
          if (!this.formData.date) this.formData.date = this.dateToISOLikeButLocal(new Date()).slice(0, 10);
          const startTime = this.formData.date + "T" + this.getTimeWithSeconds(this.formData.startTime);

          const start = Date.parse(startTime);

          const endDateTime = new Date(start + minutes * 60000);
          const endDateTimeInISOFormat = this.dateToISOLikeButLocal(endDateTime);

          this.formData.endTime = endDateTimeInISOFormat.slice(11, 16);

          this.formData.endDate = endDateTimeInISOFormat.slice(0, 10);
      },

      setDuration(type, time) {
          if (type == "end") this.formData.endTime = time;
          if (type == "start") this.formData.startTime = time;

          if (this.formData.endTime == "") {
              this.formData.duration = "";
              this.validation = {
                  error: false,
                  message: ""
              };
              return;
          }
          const startDateTime = this.formData.date + "T" + this.getTimeWithSeconds(this.formData.startTime);
          const endDateTime = this.formData.date + "T" + this.getTimeWithSeconds(this.formData.endTime);
          const start = Date.parse(startDateTime);
          const end = Date.parse(endDateTime);
          const durationInMs = end - start;
          const duration = durationInMs / 60000;
          if (duration >= 0 || duration === "") {
              this.formData.duration = duration;
              this.validation = {
                  error: false,
                  message: ""
              };
          } else {
              this.formData.duration = "";

              this.validation = {
                  error: true,
                  message: this.$t("toast.endLowerThanStartError")
              };
          }
      },

      getTimeWithSeconds(time) {
          if (!time) {
              return "00:00:00";
          }
          return time.length === 8 ? time : time + ":00";
      },
      checkDates() {
          if (!this.formData.endDate || this.formData.endDate === "") return;
          const startDate = new Date(this.formData.date);
          const endDate = new Date(this.formData.endDate);
          if (Math.sign(startDate - endDate) === 1) {
              this.formData.endDate = this.formData.date;
          }
      },

      setFormData() {
          if (this.formData.endTime === "" || !this.formData.endTime) {
              this.formData.date = new Date().toISOString().slice(0, 10);
          }
          const startTime = this.formData.date + "T" + this.getTimeWithSeconds(this.formData.startTime);

          this.checkDates();

          const endTime =
              this.formData.endTime === "" || !this.formData.endTime
                  ? null
                  : (this.formData.endDate || this.formData.date) +
                    "T" +
                    this.getTimeWithSeconds(this.formData.endTime);

          const activity = {
              id: this.formData.id === "" || !this.formData.id ? uuidv4() : this.formData.id,
              userId: JSON.parse(window.sessionStorage.AZUSER).id,
              title: this.formData.title,
              description: this.formData.description,
              startTime: startTime,
              endTime: endTime,
              title: this.formData.issue.title || this.formData.issue,
              source: this.formData.issue.source || "",
              externalId: this.formData.issue.externalId || 0
          };
          if (this.formData.additionalFields) activity.additionalFields = this.formData.additionalFields;
          return activity;
      },

      persistActivity() {
          if (this.formData.endTime !== "") {
              this.checkDuration(parseInt(this.formData.duration, 10));
          }

          if (this.formData.issue.title == "") {
              this.validation.error = true;
              this.validation.message = this.$t("toast.projectempty");
          }

          if (this.validation.error) {
              this.actionResult = {
                  error: true,
                  message: this.validation.message
              };
              this.makeToast();
              return;
          }
          const activity = this.setFormData();
          AZ.createOrUpdateActivity(activity)
              .then(response => {
                  this.$emit("update", response.data);
                  this.actionResult = {
                      error: false,
                      message: this.$t("toast.savedAt", {
                          item: this.$t("activities.item"),
                          date: new Date().toLocaleString(undefined, {
                              month: "2-digit",
                              day: "2-digit",
                              year: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: false
                          })
                      })
                  };
                  this.makeToast();
              })
              .catch(error => {
                  const data = JSON.parse(JSON.stringify(error));
                  this.actionResult = {
                      error: true,
                      message: this.$t("toast.errorOccurred", { error: data.message })
                  };
                  this.makeToast();
              });
      }
  }
};
</script>

<style scoped>
button {
  margin: 2rem 1rem;
  float: right;
}

.card-header {
  font-size: 1.2rem;
  background-color: var(--primary);
  color: white;
}

.card-body {
  padding: 0;
}

.form-container {
  padding: 2rem;
}

@media (min-width: 768px) {
  .layout-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
  }

  .layout-container > div {
      padding: 0 1rem;
  }
}
@media (max-width: 768px) {
  button {
      width: 100%;
      margin: 0.4rem 0;
      float: none;
  }
  .form-container {
      padding: 1rem;
  }
}

.vs--searchable .vs__dropdown-toggle {
  background-color: white;
}
</style>
