<!-- @format -->

<template>
    <div class="base-text-area">
        <b-form-group :label="label" label-class="form-label" :label-for="id">
            <b-input-group append="min" :id="id">
                <b-form-input v-model="formValue" @input="$emit('change', formValue)" />
            </b-input-group>
        </b-form-group>
    </div>
</template>

<script>
export default {
    name: "BaseDurationInMinutes",
    components: {},
    props: {
        label: { type: String, default: "Dauer" },
        value: { type: [String, Number], default: "" },
        id: { type: String, default: "duartinInMin" }
    },
    data() {
        return {
            formValue: "" + this.value
        };
    },

    watch: {
        value: function (newVal) {
            this.formValue = newVal;
        }
    }
};
</script>

<style scoped></style>
