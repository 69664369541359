<template>
  <div>
    <div class="d-flex justify-content-between">
      <h3>{{ $t("admin.title") }}</h3>
      <b-button
        class="mb-3"
        variant="primary"
        v-b-modal.user-form-modal
        @click="userData = { id: uuid(), role: 'user' }"
        >{{ $t("admin.createUser") }}</b-button
      >
    </div>
    <div>
      <BaseTable
        :items="users"
        :header="userHeader"
        sortBy="username"
        :sortDesc="false"
      >
        <template v-slot:cell(link)="data">
          <img
            src="edit-icon-dark.svg"
            alt="edit"
            class="mt-1 mr-3"
            v-b-modal.user-form-modal
            @click="userData = data.item"
          />
          <img
            src="delete-icon-dark.svg"
            alt="delete"
            class="mr-3"
            v-b-modal.confirm-modal
            @click="userToDelete = data.item.id"
          />
          <img
            src="download-icon-dark.svg"
            alt="download"
            class="mr-3"
            v-b-modal.export-modal
            @click="selectedUser = data.item"
          />
          <img
            src="configure-icon-dark.svg"
            alt="configure"
            v-b-modal.config-modal
            @click="selectedUser = data.item"
          />
        </template>
      </BaseTable>
      <b-modal id="user-form-modal" :title="userFormTitle" hide-footer centered>
        <UserForm :userData="userData" @update="updateTable" />
      </b-modal>
      <b-modal
        id="config-modal"
        :title="configFormTitle"
        hide-footer
        centered
        size="lg"
      >
        <SourcesForm
          :userData="userData"
          @update="updateTable"
          :selectedUserId="selectedUser.id"
        />
      </b-modal>
      <BaseConfirmationModal
        :title="$t('admin.deleteUser')"
        @confirm="removeUser()"
        @hide="closeModal('confirm-modal')"
        centered
      />
      <ExportModal :selectedUser="selectedUser" @export="exportActivities" />
    </div>
  </div>
</template>

<script>
import AZ from "@/utils/AzApi";
import BaseTable from "@/components/BaseTable.vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";
import BaseConfirmationModal from "@/components/BaseConfirmationModal.vue";
import UserForm from "@/components/UserForm.vue";
import SourcesForm from "@/components/SourcesForm.vue";
import { v4 as uuidv4 } from "uuid";
import ExportModal from "../components/ExportModal.vue";

export default {
  name: "Admin",
  components: {
    BaseTable,
    BaseDatePicker,
    BaseConfirmationModal,
    UserForm,
    SourcesForm,
    ExportModal,
  },
  data() {
    return {
      users: [],
      userHeader: [
        { key: "id", label: this.$t("admin.userId"), sortable: true },
        { key: "username", label: this.$t("admin.username"), sortable: true },
        { key: "role", label: this.$t("admin.role"), sortable: true },
        { key: "link", label: this.$t("admin.edit"), sortable: false },
      ],
      viewportWidth: window.innerWidth,
      text: "",
      actionResult: {
        error: false,
        message: "",
      },
      userToDelete: 0,
      userData: {},

      startDate: "",
      endDate: "",
      exportAdditionalFields: false,
      selectedUser: {},
    };
  },

  computed: {
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? this.$t("toast.saveError", { item: this.$t("admin.item") })
        : this.$t("toast.saveSuccess", { item: this.$t("admin.item") });
    },
    userFormTitle() {
      return this.userData.username === undefined
        ? this.$t("admin.createUser")
        : this.$t("admin.updateUser", { username: this.userData.username });
    },
    configFormTitle() {
      return this.$t("admin.configUser", {
        username: this.selectedUser.username,
      });
    },
  },

  mounted() {
    this.retrieveUsers();
  },
  methods: {
    uuid() {
      return uuidv4();
    },

    exportActivities(data) {
      AZ.getActivitiesForUser(
        data.selectedUser,
        data.startDate,
        data.endDate,
        data.exportAdditionalFields
      ).then(() => {
        (this.startDate = ""), (this.endDate = ""), (this.selectedUser = {});
        this.closeModal("export-modal");
      });
    },

    closeModal(id) {
      this.$bvModal.hide(id);
    },
    removeUser() {
      AZ.deleteUser(this.userToDelete)
        .then(() => {
          const indexToDelete = this.users.findIndex(
            (user) => user.id === this.userToDelete
          );
          this.users.splice(indexToDelete, 1);
          this.actionResult = {
            error: false,
            message: this.$t("toast.deleteSuccess", { item: "User" }),
          };
          this.makeToast();
        })
        .catch(() => {
          this.actionResult = {
            error: true,
            message: this.$t("toast.deleteError", { item: "User" }),
          };
          this.makeToast();
        });
      this.closeModal("confirm-modal");
    },
    retrieveUsers() {
      AZ.retrieveAllUsers().then((response) => {
        this.users = response.data;
      });
    },
    updateTable() {
      this.retrieveUsers();
      this.closeModal("user-form-modal");
    },
  },
};
</script>

<style scoped>
.info {
  color: var(--primary);
}
</style>
