<template>
  <div v-if="isNotLoginPage">
    <b-navbar toggleable type="light" variant="bg-medium">
      <img src="app-icon-lg.svg" alt="" />
      <b-navbar-brand href="/">{{ $t("navbar.appName") }}</b-navbar-brand>
      <div class="d-flex align-items-end">
        <div class="username" v-if="!isMobile">{{ user.username || "" }}</div>
        <b-navbar-toggle target="navbar-toggle-collapse">
          <template v-slot:default="{ expanded }">
            <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
            <b-icon v-else icon="chevron-bar-down"></b-icon>
          </template>
        </b-navbar-toggle>
      </div>
      <b-collapse id="navbar-toggle-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item class="username" v-if="isMobile">
            {{ user.username || "" }}
          </b-nav-item>
          <b-nav-item v-if="canView('account')" href="#/account">{{
            $t("navbar.account")
          }}</b-nav-item>
          <b-nav-item v-if="canView('admin')" href="#/admin">{{
            $t("navbar.userMngmt")
          }}</b-nav-item>
          <b-nav-item href="/">{{ $t("navbar.home") }}</b-nav-item>
          <b-nav-item href="#/logout">{{ $t("navbar.logout") }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
// @ is an alias to /src
import AZ from "@/utils/AzApi";
import config from "@/utils/config";

export default {
  name: "BaseNavbar",
  components: {},
  data() {
    return {
      user: AZ.user(),
      rights: JSON.parse(JSON.stringify(config))?.rights?.view,
    };
  },
  computed: {
    isNotLoginPage() {
      return this.$route.name !== "login";
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
  },
  created() {
    window.addEventListener("AZUSERCHANGE", () => {
      this.user = AZ.user();
    });
  },
  methods: {
    canView(page) {
      if (this.rights[page] && this.rights[page].includes(this.user.role))
        return true;
      else return false;
    },
  },
};
</script>

<style scoped>
.nav-link {
  text-align: right;
}

.nav {
  width: 100vw;
}

a.navbar-brand {
  color: var(--primary);
  padding: 0;
}

.username {
  color: var(--primary);
  margin-right: 1rem;
}

img {
  height: 40px;
}

@media (max-width: 768px) {
  img {
    height: 24px;
    margin-bottom: 4px;
  }
  a.navbar-brand {
    padding: 0;
    font-size: 1rem;
  }
  .username {
    margin-right: 0;
  }
}
</style>
