<template>
  <div class="activities-table">
    <BaseTable :items="items" :header="headerItems">
      <template v-slot:cell(link)="data">
        <img
          v-if="canView('update')"
          src="edit-icon-dark.svg"
          alt="edit"
          class="mt-1 mx-3"
          @click="$emit('edit', data.item)"
        />
        <img
          v-if="canView('delete')"
          src="delete-icon-dark.svg"
          alt="delete"
          v-b-modal.confirm-modal
          @click="activityToDelete = data.item.id"
        />
      </template>
      <template v-slot:cell(state)="data">
        <div v-if="canView('copy')">
          <img
            v-if="data.item.running"
            src="/stop-icon.svg"
            alt="stop"
            @click="$emit('stop', data.item)"
          />
          <img
            v-else
            src="refresh-icon-dark.svg"
            alt="restart"
            @click="$emit('restart', data.item)"
          />
        </div>
      </template>

      <template v-if="isMobile" v-slot:row-details="row">
        <b-card @click="toggleDescription(row.item.description)">
          <b-row class="mb-2">
            <b-col>{{ row.item.title }}</b-col>
          </b-row>
          <b-row class="mb-2" v-if="description === row.item.description">
            <hr />
            <b-col class="font-italic">{{ row.item.description }}</b-col>
          </b-row>
        </b-card>
      </template>
    </BaseTable>
    <BaseConfirmationModal
      :title="$t('activities.delete')"
      @confirm="deleteActivity()"
      @hide="closeModal()"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import BaseTable from "@/components/BaseTable.vue";
import BaseConfirmationModal from "@/components/BaseConfirmationModal.vue";
import AZ from "@/utils/AzApi";
import config from "@/utils/config";

export default {
  name: "ActivitiesTable",
  components: {
    BaseTable,
    BaseConfirmationModal,
  },
  props: {
    entries: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      activityToDelete: 0,
      description: undefined,
      header: JSON.parse(JSON.stringify(config))?.table.activities.header,
      headerMobile: JSON.parse(JSON.stringify(config))?.table.activities
        .headerMobile,
      rights: JSON.parse(JSON.stringify(config))?.rights.activity,
      role: AZ.user().role,
    };
  },
  mounted() {
    this.header.forEach((column) => (column.label = this.$t(column.label)));
    this.headerMobile.forEach(
      (column) => (column.label = this.$t(column.label))
    );
  },
  computed: {
    headerItems() {
      return window.innerWidth < 768 ? this.headerMobile : this.header;
    },
    isMobile() {
      return window.innerWidth < 768;
    },
    items() {
      return this.entries;
    },
    alertType() {
      return this.actionResult.error ? "danger" : "primary";
    },
    toastHeader() {
      return this.actionResult.error
        ? this.$t("toast.saveError", { item: this.$t("activities.item") })
        : this.$t("toast.saveSuccess", { item: this.$t("activities.item") });
    },
  },
  methods: {
    canView(activity) {
      if (this.rights[activity] && this.rights[activity].includes(this.role))
        return true;
      else return false;
    },
    toggleDescription(description) {
      if (this.description === undefined || this.description !== description)
        this.description = description;
      else this.description = undefined;
    },
    makeToast() {
      this.$bvToast.toast(this.actionResult.message, {
        title: this.toastHeader,
        variant: this.alertType,
        solid: true,
      });
    },
    closeModal() {
      this.$bvModal.hide("confirm-modal");
    },

    deleteActivity() {
      AZ.deleteActivity(this.activityToDelete)
        .then(() => {
          const indexToDelete = this.items.findIndex(
            (item) => item.id === this.activityToDelete
          );
          this.items.splice(indexToDelete, 1);
          this.closeModal();
          this.actionResult = {
            error: false,
            message: this.$t("toast.deletedAt", {
              item: this.$t("activities.item"),
              date: new Date().toLocaleString(undefined, {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              }),
            }),
          };
          this.makeToast();
        })
        .catch((error) => {
          this.actionResult = {
            error: true,
            message: this.$t("toast.errorOccurred", { error: error.message }),
          };
          this.makeToast();
        });
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.table-container {
  padding: 1.2rem;
}
</style>
