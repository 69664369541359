<template>
  <b-form-group :label="label">
    <base-picker
      v-model="duration"
      :minute-interval="15"
      :hour-range="[[0, 10]]"
      manual-input
      input-width="100%"
      @change="$emit('change', duration)"
    ></base-picker>
  </b-form-group>
</template>

<script>
import TimeConverter from "../mixins/TimeConverter";
import BasePicker from "../components/BasePicker";

export default {
  name: "BaseDurationPicker",
  components: { BasePicker },
  mixins: [TimeConverter],
  props: {
    value: [String, Number],
    label: String,
    id: String,
  },
  data() {
    return {
      labels: {
        de: {
          labelHours: "Stunden",
          labelMinutes: "Minuten",
          labelSeconds: "Sekunden",
          labelIncrement: "Erhöhen",
          labelDecrement: "Verringern",
          labelSelected: "Ausgewählte Zeit",
          labelNoTimeSelected: "Keine Zeit ausgewählt",
        },
      },
      duration: null,
    };
  },
  mounted() {
    this.duration = this.value;
    this.$emit("change", this.duration);
  },
  watch: {
    value: function(newVal) {
      if (isNaN(newVal) || newVal === "") {
        this.duration = newVal;
      } else {
        this.duration = this.convertMinutesInHHmmFormat(newVal);
      }
    },
  },
  methods: {
    roundMinutes(timeArray) {
      const minutes = parseInt(
        timeArray[timeArray.length - 2] + timeArray[timeArray.length - 1]
      );
      let roundedMinutes = minutes;
      let hours =
        timeArray.length === 3
          ? parseInt(timeArray[0])
          : parseInt(timeArray[0] + timeArray[1]);

      if (minutes === 0) roundedMinutes = "00";
      else if (minutes <= 15) roundedMinutes = "15";
      else if (minutes <= 30) roundedMinutes = "30";
      else if (minutes <= 45) roundedMinutes = "45";
      else if (minutes > 45) {
        roundedMinutes = "00";
        hours++;
      }
      const hourString = hours < 10 ? "0" + hours : hours;
      return hourString + ":" + roundedMinutes;
    },
    round() {
      const timeArray = this.duration.replace(":", "").split("");
      this.duration = this.roundMinutes(timeArray);
    },
    formatInput() {
      const timeArray = this.duration.split("");
      switch (this.duration.length) {
        case 1:
          this.duration = "0" + this.duration + ":00";
          break;
        case 2:
          if (this.duration.match(/^[0-2][0-9]$/) !== null)
            this.duration += ":00";
          break;
        case 3:
          this.duration = this.roundMinutes(timeArray);
          break;
        case 4:
          if (this.duration.match(/^[0-2][0-9][0-5][0-9]$/) !== null)
            this.duration = this.roundMinutes(timeArray);
          break;
      }
    },
    autocompleteDuration() {
      if (!this.duration || this.duration === "") return;
      else if (this.duration?.match(/^[0-2]*[0-9]:[0-5][0-9]$/)) {
        this.round();
      } else if (this.duration?.match(/^[0-9]+$/)) {
        this.formatInput();
      }
      this.$emit("change", this.duration);
    },
    setDurationInPicker() {
      if (!this.duration || this.duration === "") {
        this.duration = "00:00";
      }
    },
    sliceDuration() {
      if (this.duration.match(/^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/)) {
        this.duration = this.duration.substring(0, 5);
      }
      this.$emit("change", this.duration);
    },
  },
};
</script>

<style scoped></style>
